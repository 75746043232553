import React from "react";
import { cn } from "../lib/helpers";

import PortableText from "./portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import { getGatsbyImageData } from "gatsby-source-sanity";
import clientConfig from "../../client-config";
import { Link } from "gatsby";

import * as styles from "./promoMagazin.module.css";
import * as commonStyles from "../styles/common.module.css";

function PromoMagazin(props) {
  const { magazin } = props;

  const promoted = magazin[0];
  const imageData = getGatsbyImageData(promoted.mainImage, {}, clientConfig.sanity);

  return (
    <div className={styles.container1}>
      <h2 className={cn(styles.mag, commonStyles.fontButlerMedium)}>Magazín</h2>
      <Link to={`/magazin/${promoted.slug.current}`}>
        <div className={styles.container3}>
          <div
            className={cn(styles.container2, commonStyles.fontButler, commonStyles.colorDarkGray)}
          >
            <div className={styles.image}>
              <GatsbyImage image={imageData} alt={promoted.mainImage.alt} />
            </div>
            <div>
              <h3
                className={cn(
                  styles.title,
                  commonStyles.fontButlerMedium,
                  commonStyles.colorAccent
                )}
              >
                {promoted.title}
              </h3>
              <div className={styles.bodyPreview}>
                <PortableText blocks={promoted._rawBody} />
              </div>
            </div>
          </div>
        </div>
      </Link>

      <Link to={"/magazin"}>
        <div className={cn(styles.more, commonStyles.fontReleway, commonStyles.colorDarkGray)}>
          {/* <img className={cn(styles.arrow)} src={Arrow} alt="Šipka" /> */}
          Více článků
        </div>
      </Link>
    </div>
  );
}

export default PromoMagazin;
