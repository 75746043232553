import React from "react";

import Layout from "../containers/layout";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";

import InfoRows from "../components/InfoRows";
import Services from "../components/services";
import Hero from "../components/hero";
import Video from "../components/video";
import Actions from "../components/actions";
import Reference from "../components/reference";
import PromoMagazin from "../components/promoMagazin";
import PromoGalerie from "../components/promoGalerie";
import FadeInSection from "../components/FadeIn";

const Page = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;
  const allServices = data && data?.allServices?.nodes;
  const magazinPreview = data && data?.magazinPreview?.nodes;
  const tags = data && data?.tags?.nodes;

  const content = (page._rawContent || [])
    .filter((c) => !c.disabled)
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "infoRows":
          el = (
            <FadeInSection>
              <InfoRows key={c._key} {...c} />
            </FadeInSection>
          );
          break;
        case "services":
          el = <Services key={c._key} {...c} />;
          break;
        case "hero":
          el = (
            <FadeInSection>
              <Hero key={c._key} {...c} />
            </FadeInSection>
          );
          break;
        case "mainVideo":
          el = (
            <FadeInSection>
              <Video key={c._key} {...c} />
            </FadeInSection>
          );
          break;
        case "actionsPlug":
          el = (
            <FadeInSection>
              <Actions key={c._key} {...c} actionsData={data?.actions?.actions} />
            </FadeInSection>
          );
          break;
        case "mainReference":
          el = (
            <FadeInSection>
              <Reference key={c._key} {...c} />
            </FadeInSection>
          );
          break;
        case "promoMagazin":
          el = (
            <FadeInSection>
              <PromoMagazin key={c._key} {...c} magazin={magazinPreview} />
            </FadeInSection>
          );
          break;
        case "promoGalerie":
          el = (
            <FadeInSection>
              <PromoGalerie key={c._key} {...c} />
            </FadeInSection>
          );
          break;
        default:
          el = null;
      }
      return el;
    });

  return (
    <Layout services={allServices} tags={tags} magazin={magazinPreview}>
      <SEO
        title={"Beauty Body Clinic"}
        description={
          "Beauty Body Clinic je privátní klinika, která se zcela liší od klasických center krásy. Z původního oboru estetické medicíny se díky propojení s tradiční čínskou medicínou stalo unikátní místo, které vyhledávají jak klienti z dalekého okolí, tak i řada celebrit a významných osobností."
        }
        keywords={["Beauty", "Clinic", "Krása", "Akupunktura", "Rty", "Vlasy"]}
        bodyAttr={{
          class: "leading-normal tracking-normal text-white gradient",
        }}
      />
      <div>{content}</div>
    </Layout>
  );
};

export default Page;
